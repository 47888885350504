import { lazy } from 'react';
import GlobalSearchDetail from '../../component/globalSearchDetail';

const Listing = lazy(() => import('../../../features/listings/listing/Listing'));
const ListingList = lazy(() => import('../../../features/listings/ListingList'));
const ListingAdvancedSearch = lazy(
    () =>
        import(
            '../../../features/listings/advancedSearch/components/ListingAdvancedSearch'
        ),
);
const MergeListing = lazy(
    () => import('../../../features/listings/mergeListing/component/mergeListing'),
);
const Profile = lazy(() => import('../../../features/agents/agentDashboard/Agent'));
const Office = lazy(() => import('../../../features/offices/office/Office'));
const OfficeList = lazy(() => import('../../../features/offices/OfficeList'));
const SalesTeamList = lazy(() => import('../../../features/salesTeam/SalesTeamList'));
const AgentList = lazy(() => import('../../../features/agents/AgentList'));
const Applications = lazy(() => import('../../../features/applications/Applications'));
const Home = lazy(() => import('../../../features/homepage'));
const MyBusiness = lazy(() => import('../../../features/myBusiness'));
const UserList = lazy(() => import('../../../features/users/UserList'));
const MyTask = lazy(() => import('../../../features/myTasks'));
const SalesTeamDetail = lazy(() => import('../../../features/salesTeam/salesTeamDetail'));
const MasqueradeAs = lazy(() => import('../../../features/masquerade'));
const UserDetails = lazy(() => import('../../../features/users/userDetails/UserDetails'));
const Advertisement = lazy(() => import('../../../features/advertisements'));
const MediaOutlets = lazy(() => import('../../../features/advertisements/mediaOutlets'));
const ViewAdvertisement = lazy(
    () =>
        import(
            '../../../features/advertisements/advertisementHolder/viewAdvertisement/ViewAdvertisement'
        ),
);
const UserNotifications = lazy(
    () =>
        import(
            '../../../features/notifications/userNotifications/components/UserNotifications'
        ),
);
const ManageNotifications = lazy(
    () => import('../../../features/notifications/manage/components/NotificationsTable'),
);
const PressItems = lazy(() => import('../../../features/advertisements/pressItem'));
const Marketing = lazy(() => import('../../../features/newMarketing'));
const ListingUtilitarian = lazy(
    () => import('../../../features/listings/utilitarianView/UtilitarianView'),
);
const OtherMarketing = lazy(
    () => import('../../../features/otherMarketing/OtherMarketing'),
);
const DevelopmentList = lazy(
    () => import('../../../features/developments/DevelopmentList'),
);
const DevelopmentDetail = lazy(
    () => import('../../../features/developments/developmentDetails/DevelopmentDetails'),
);
const SelectSoldsUpload = lazy(
    () =>
        import(
            '../../../features/excelUpload/components/selectSoldsListingsUpload/SelectSoldsUpload'
        ),
);
const Reporting = lazy(() => import('../../../features/reporting/Reporting'));
const ListingCommission = lazy(
    () => import('../../../shared/component/listingCommission'),
);

export const userRoleLookups = [
    {
        key: 'C33EE1A5-585E-41F6-B9B0-93C745248FB4',
        value: 'Accounting User',
        isSelected: false,
        grouping: 'normal',
    },
    {
        key: 'CB9D9788-ED11-481C-AB75-8E67B5AD798C',
        value: 'Agent',
        isSelected: false,
        grouping: 'normal',
    },
    {
        key: '90130FC6-856B-4663-94EA-E6CA5F3CFC80',
        value: 'Branch Manager',
        isSelected: false,
        grouping: 'normal',
    },
    {
        key: '8AF5CE9A-7E86-4FC2-88F4-8025BBACC2A8',
        value: 'Corporate Employee',
        isSelected: false,
        grouping: 'normal',
    },
    {
        key: 'D2887819-EA9B-495C-ADCD-FD45C37E5656',
        value: 'Corporate Finance Employee',
        isSelected: false,
        grouping: 'normal',
    },
    {
        key: 'ACF1F28A-D82B-4426-88FE-18689056CB97',
        value: 'Corporate HR Employee',
        isSelected: false,
        grouping: 'normal',
    },
    {
        key: '5EC32747-11CD-44CE-823E-2C5FA94447C2',
        value: 'Corporate Legal Employee',
        isSelected: false,
        grouping: 'normal',
    },
    {
        key: '6DCC316E-4AC0-4A90-807B-9439E71F789E',
        value: 'Corporate Marketing Employee',
        isSelected: false,
        grouping: 'normal',
    },
    {
        key: '686B062A-5687-4EEA-9B03-56C79B4A07D4',
        value: 'Guest',
        isSelected: false,
        grouping: 'normal',
    },
    {
        key: '54E6A602-AFA2-4D03-885D-DB346B24801D',
        value: 'Marketing Coordinator',
        isSelected: false,
        grouping: 'normal',
    },
    {
        key: 'FA0FF182-DFDE-4A9D-A5C6-7BF757E26D25',
        value: 'Office Coordinator',
        isSelected: false,
        grouping: 'normal',
    },
    {
        key: '4B4BA09E-3660-4989-B00D-4B8101E69A78',
        value: 'Staff',
        isSelected: false,
        grouping: 'normal',
    },
    {
        key: '72B84076-B951-476D-8C4E-BF425D900DC9',
        value: 'System Administrator',
        isSelected: false,
        grouping: 'normal',
    },
    {
        key: '0EE60FBE-035B-48F8-A757-F3D0BCCD684E',
        value: 'Masquerade',
        isSelected: false,
        grouping: 'permission',
    },
    {
        key: 'DCA9ED76-F89E-42D4-B961-0C2B0E9B6839',
        value: 'Publish Listing',
        isSelected: false,
        grouping: 'permission',
    },
    {
        key: '59AF7643-2AB9-4828-9BCE-3C7CCBCA37EB',
        value: 'Listing Draft Creation',
        isSelected: false,
        grouping: 'permission',
    },
    {
        key: 'CDB3E812-D6CB-4A7E-AF62-29BD90E5B2B3',
        value: 'Listing Edit',
        isSelected: false,
        grouping: 'permission',
    },
    {
        key: 'F1BB1812-2AD1-4EF5-A203-3C1E758CAD02',
        value: 'Agent Spot Booking',
        isSelected: false,
        grouping: 'permission',
    },
    {
        key: 'D1379E00-1048-4CF2-94B4-82B980F49D71',
        value: 'Disable Listing Price Edit',
        isSelected: false,
        grouping: 'permission',
    },
    {
        key: '62306C81-A524-41ED-BD3B-093037E388EE',
        value: 'Disable Listing Dates Edit',
        isSelected: false,
        grouping: 'permission',
    },
    {
        key: 'A7AAB39E-1FFF-4021-B1A8-A711FFE39B47',
        value: 'Disable Listing Agents Edit',
        isSelected: false,
        grouping: 'permission',
    },
    {
        key: 'E7D7CF84-FCF9-4A69-B031-7A987D8781B7',
        value: 'Disable Listing AdCopy Edit',
        isSelected: false,
        grouping: 'permission',
    },
    {
        key: 'CE6B1E02-CA92-423B-9357-2B3496016527',
        value: 'Disable Showcase Edit',
        isSelected: false,
        grouping: 'permission',
    },
    {
        key: 'F74FA144-0771-4154-BFB3-45DCE5ED663B',
        value: 'Listing Photo Reorder',
        isSelected: false,
        grouping: 'permission',
    },
    {
        key: '136C834A-A0A4-4479-942E-9CB9B712215E',
        value: 'Power User',
        isSelected: false,
        grouping: 'permission',
    },
];

export const userRoles = {
    accountingUser: userRoleLookups[0],
    agent: userRoleLookups[1],
    branchManager: userRoleLookups[2],
    corporateEmployee: userRoleLookups[3],
    corporateFinanceEmployee: userRoleLookups[4],
    corporateHREmployee: userRoleLookups[5],
    corporateLegalEmployee: userRoleLookups[6],
    corporateMarketingEmployee: userRoleLookups[7],
    guest: userRoleLookups[8],
    marketingCoordinator: userRoleLookups[9],
    officeCoordinator: userRoleLookups[10],
    staff: userRoleLookups[11],
    systemAdmin: userRoleLookups[12],
    masquerade: userRoleLookups[13],
    publishListing: userRoleLookups[14],
    draftCreation: userRoleLookups[15],
    listingEdit: userRoleLookups[16],
    agentSpotBooking: userRoleLookups[17],
    disableListingPriceEdit: userRoleLookups[18],
    disableListingDatesEdit: userRoleLookups[19],
    disableListingAgentsEdit: userRoleLookups[20],
    disableAdCopyEdit: userRoleLookups[21],
    disableShowcaseEdit: userRoleLookups[22],
    listingPhotoReorder: userRoleLookups[23],
    powerUser: userRoleLookups[24],
};

export const roleFilter = [
    {
        route: '/eng/agents',
        permissions: [
            userRoles.agent.key,
            userRoles.corporateMarketingEmployee.key,
            userRoles.marketingCoordinator.key,
            userRoles.systemAdmin.key,
        ],
        component: AgentList,
        isProtected: true,
        navKey: 'agents',
        validateActionKey: 'agentId',
    },
    {
        route: '/eng/agent/:agentId',
        permissions: [
            userRoles.agent.key,
            userRoles.corporateMarketingEmployee.key,
            userRoles.marketingCoordinator.key,
            userRoles.systemAdmin.key,
        ],
        component: Profile,
        isProtected: true,
    },
    {
        route: '/eng/applications',
        permissions: [],
        component: Applications,
        isProtected: false,
        navKey: 'applications',
    },
    {
        route: '/eng/business',
        permissions: [userRoles.agent.key],
        component: MyBusiness,
        isProtected: true,
        navKey: 'business',
    },
    {
        route: '/eng/home',
        permissions: [],
        isProtected: false,
        component: Home,
    },
    {
        route: '/eng/listings',
        permissions: [],
        component: ListingList,
        isProtected: false,
        navKey: 'listings',
    },
    {
        route: '/eng/listing/mergeListing',
        permissions: [userRoles.staff.key, userRoles.systemAdmin.key],
        isProtected: true,
        component: MergeListing,
    },
    {
        route: '/eng/listing/:listingId',
        permissions: [],
        isProtected: false,
        component: Listing,
    },
    {
        route: '/eng/listingListCommissions',
        permissions: [userRoles.staff.key, userRoles.systemAdmin.key],
        isProtected: true,
        component: ListingCommission,
    },
    {
        route: '/eng/advancedsearch',
        permissions: [],
        component: ListingAdvancedSearch,
        isProtected: false,
    },
    {
        route: '/eng/offices',
        permissions: [
            userRoles.corporateMarketingEmployee.key,
            userRoles.marketingCoordinator.key,
            userRoles.systemAdmin.key,
        ],
        component: OfficeList,
        isProtected: true,
        navKey: 'offices',
    },
    {
        route: '/eng/office/:officeId',
        permissions: [
            userRoles.corporateMarketingEmployee.key,
            userRoles.marketingCoordinator.key,
            userRoles.systemAdmin.key,
        ],
        component: Office,
        isProtected: true,
    },
    {
        route: '/eng/salesTeams',
        permissions: [
            userRoles.agent.key,
            userRoles.corporateMarketingEmployee.key,
            userRoles.marketingCoordinator.key,
            userRoles.systemAdmin.key,
        ],
        component: SalesTeamList,
        isProtected: true,
        navKey: 'salesTeams',
        validateActionKey: 'salesTeamId',
    },
    {
        route: '/eng/salesTeam/:teamId',
        permissions: [
            userRoles.agent.key,
            userRoles.corporateMarketingEmployee.key,
            userRoles.marketingCoordinator.key,
            userRoles.systemAdmin.key,
        ],
        component: SalesTeamDetail,
        isProtected: true,
    },
    {
        route: '/eng/searchDetailed',
        permissions: [
            userRoles.agent.key,
            userRoles.staff.key,
            userRoles.branchManager.key,
            userRoles.systemAdmin.key,
        ],
        component: GlobalSearchDetail,
        isProtected: false,
    },
    {
        route: '/eng/users',
        permissions: [userRoles.systemAdmin.key],
        component: UserList,
        isProtected: true,
        navKey: 'users',
    },
    {
        route: '/eng/user/:userId',
        permissions: [userRoles.systemAdmin.key],
        component: UserDetails,
        isProtected: true,
    },
    // Check on this later
    {
        route: '/eng/mytask',
        permissions: [
            userRoles.agent.key,
            userRoles.staff.key,
            userRoles.branchManager.key,
        ],
        component: MyTask,
        isProtected: true,
        navKey: 'mytask',
    },
    {
        route: '/eng/notifications',
        permissions: [],
        component: UserNotifications,
        isProtected: false,
        navKey: 'notifications',
    },
    {
        route: '/eng/managenotifications',
        permissions: [userRoles.systemAdmin.key],
        component: ManageNotifications,
        isProtected: true,
    },
    {
        route: '/eng/masquerade',
        permissions: [userRoles.masquerade.key, userRoles.systemAdmin.key],
        component: MasqueradeAs,
        navKey: 'masquerade',
        isProtected: true,
    },
    {
        route: '/eng/advertisements',
        permissions: [],
        component: Advertisement,
        navKey: 'advertisements',
        isProtected: false,
    },
    {
        route: '/eng/viewAdvertisement/:advertisementId?',
        permissions: [],
        component: ViewAdvertisement,
        navKey: 'viewAdvertisement',
        isProtected: false,
    },
    {
        route: '/eng/mediaOutlets',
        permissions: [userRoles.systemAdmin.key],
        component: MediaOutlets,
        navKey: 'mediaOutlets',
        isProtected: true,
    },
    {
        route: '/eng/press',
        permissions: [
            userRoles.corporateMarketingEmployee.key,
            userRoles.marketingCoordinator.key,
            userRoles.systemAdmin.key,
        ],
        component: PressItems,
        navKey: 'press',
        isProtected: true,
    },
    {
        route: '/eng/marketing',
        permissions: [],
        component: Marketing,
        navKey: 'marketing',
        isProtected: false,
    },
    {
        route: '/eng/listingUtilitarian/:listingId',
        permissions: [
            userRoles.agent.key,
            userRoles.staff.key,
            userRoles.branchManager.key,
            userRoles.systemAdmin.key,
        ],
        isProtected: true,
        component: ListingUtilitarian,
    },
    {
        route: '/eng/otherMarketing',
        permissions: [
            userRoles.agent.key,
            userRoles.corporateMarketingEmployee.key,
            userRoles.marketingCoordinator.key,
            userRoles.staff.key,
            userRoles.systemAdmin.key,
        ],
        component: OtherMarketing,
        navKey: 'otherMarketing',
        isProtected: true,
    },
    {
        route: '/eng/developments',
        permissions: [
            userRoles.agent.key,
            userRoles.corporateMarketingEmployee.key,
            userRoles.marketingCoordinator.key,
            userRoles.staff.key,
            userRoles.systemAdmin.key,
        ],
        component: DevelopmentList,
        navKey: 'developments',
        isProtected: true,
    },
    {
        route: '/eng/developmentDetails/:developmentId',
        permissions: [
            userRoles.agent.key,
            userRoles.corporateMarketingEmployee.key,
            userRoles.marketingCoordinator.key,
            userRoles.staff.key,
            userRoles.systemAdmin.key,
        ],
        component: DevelopmentDetail,
        isProtected: true,
    },
    {
        route: '/eng/selectSoldsUpload',
        permissions: [userRoles.systemAdmin.key],
        component: SelectSoldsUpload,
        navKey: 'selectSoldsUpload',
        isProtected: true,
    },
    {
        route: '/eng/reporting',
        permissions: [
            userRoles.corporateMarketingEmployee.key,
            userRoles.marketingCoordinator.key,
            userRoles.systemAdmin.key,
        ],
        component: Reporting,
        navKey: 'reporting',
        isProtected: true,
    },
];
