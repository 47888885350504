import { getCall, putCallRaw, postCall } from '../../../utils/api/apiWrapper';
import { VideoModel } from '../../models/video/videoModels';
import {
    BrightCoveData,
    BrightcoveResponse,
} from '../../models/video/brightcove/brightcoveModels';

export function getVideosFromApi(
    entityId: string,
    entityTypeId: number,
    videoTypeId?: number,
): Promise<VideoModel[]> {
    return getCall<VideoModel[]>(
        videoTypeId
            ? `brightcove/videos/${entityId}/${entityTypeId}/${videoTypeId}`
            : `brightcove/videos/${entityId}/${entityTypeId}`,
    );
}

export function updateVideoApi(video: VideoModel): Promise<Response> {
    return putCallRaw(`brightcove/videodetail`, video);
}

export function postVideoMetaData(
    id: string,
    data: BrightCoveData,
    fromPhotographer?:boolean
): Promise<BrightcoveResponse> {
    return postCall<BrightCoveData, BrightcoveResponse>(`listing/${id}/videos`, data, !!fromPhotographer);
}
