import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../../app/store';
import {
    ListingSearchRequest,
    SearchedListingListModel,
} from '../../../shared/models/listing/commonModel';
import {
    confirmMergeListingApi,
    mergeListingApi,
    searchListings,
} from './mergeListingApi';
import { orderBy } from 'lodash';
import Logger from '../../../utils/logging/logger';
import { setError } from '../../../shared/slices/messaging/messagingSlice';
import {
    MergeListingRequestInterface,
    MergeListingResponseInterface,
    MergeListingStateInterface,
} from './mergeListingModels';
import { ChangeEvent } from 'react';

const MergeListingState: MergeListingStateInterface = {
    searchedFromListings: [],
    searchedToListings: [],
    isLoading: false,
    confirmSuccess: false,
    confirmResponseFetch: false,
    searchLoader: false,
    mergeListingRequest: {
        fromListingId: '',
        toListingId: '',
        includeMlsId: false,
        includeTridentId: false,
        includeListingDetails: false,
        includeListingFeatures: false,
        includeAdCopy: false,
        includeDomain: false,
        includeNotes: false,
        includeMarketing: false,
        includeAnalytics: false,
        includeOpenHouses: false,
        includeShowings: false,
        includeMedia: false,
        shouldDeleteFromListing: false,
    },
    mergeListingResponse: {
        fromListingDetails: {
            id: '',
            title: '',
            address1: '',
            address2: '',
            city: '',
            zip: '',
            status: '',
            listingStatusId: 0,
            dealType: '',
            propertyType: '',
            propertyTypeId: 0,
            county: '',
            state: '',
            businessPurposeType: '',
            businessPurposeTypeId: 0,
            mlsNumber: '',
            rooms: 0,
            bedrooms: 0,
            fullBaths: 0,
            halfBaths: 0,
            interiorSize: 0,
            exteriorSize: 0,
            lotSize: 0,
            lotSizeUnit: '',
            lotSizeTypeId: 0,
            priceUponRequest: false,
            showAddress: false,
            neighborhood: '',
            office: '',
            officeId: '',
            currentPrice: 0,
            initialPrice: 0,
            lastAskingPrice: 0,
            displayPrice: 0,
            mlsPrice: 0,
            soldPrice: 0,
            listSideAgents: [],
            saleSideAgents: [],
            ownerDetails: [
                {
                    firstName: '',
                    lastName: '',
                    address: '',
                    address2: '',
                    regionId: '',
                    zip: '',
                    city: '',
                    displayOrder: 0,
                    ownerAddressDifferentFromPropertyAddress: false,
                },
            ],
            prices: [
                {
                    price: 0,
                    rentalPeriod: '',
                },
            ],
            listingDate: '',
            expirationDate: '',
            closedDate: '',
            newListingUntil: '',
            contractDate: '',
            canceledDate: '',
            projectedListDate: '',
            publishedDate: '',
            lastPublishedDate: '',
            tridentDealNumber: '',
            internetAdCopy: '',
            domainInfo: {
                privateSite: false,
                domainUrl: '',
                publishedSite: false,
            },
            notes: [
                {
                    id: '',
                    note: '',
                    userId: '',
                    createdDate: null,
                    firstName: '',
                    lastName: '',
                    listingId: '',
                },
            ],
            listingFeatures: '',
            totalPastMarketings: 0,
            totalViews: 0,
            totalOpenHouses: 0,
            totalShowings: 0,
            totalMedia: {
                mainPhotos: 0,
                floorPlans: 0,
                showcaseHeroImages: 0,
                photographersUploadImages: 0,
                videos: 0,
                virtualTour: 0,
            },
        },
        toListingDetails: {
            id: '',
            title: '',
            address1: '',
            address2: '',
            city: '',
            zip: '',
            status: '',
            listingStatusId: 0,
            dealType: '',
            propertyType: '',
            propertyTypeId: 0,
            county: '',
            state: '',
            businessPurposeType: '',
            businessPurposeTypeId: 0,
            mlsNumber: '',
            rooms: 0,
            bedrooms: 0,
            fullBaths: 0,
            halfBaths: 0,
            interiorSize: 0,
            exteriorSize: 0,
            lotSize: 0,
            lotSizeUnit: '',
            lotSizeTypeId: 0,
            priceUponRequest: false,
            showAddress: false,
            neighborhood: '',
            office: '',
            officeId: '',
            currentPrice: 0,
            initialPrice: 0,
            lastAskingPrice: 0,
            displayPrice: 0,
            mlsPrice: 0,
            soldPrice: 0,
            listSideAgents: [],
            saleSideAgents: [],
            ownerDetails: [
                {
                    firstName: '',
                    lastName: '',
                    address: '',
                    address2: '',
                    regionId: '',
                    zip: '',
                    city: '',
                    displayOrder: 0,
                    ownerAddressDifferentFromPropertyAddress: false,
                },
            ],
            prices: [
                {
                    price: 0,
                    rentalPeriod: '',
                },
            ],
            internetAdCopy: '',
            domainInfo: {
                privateSite: false,
                domainUrl: '',
                publishedSite: false,
            },
            notes: [
                {
                    id: '',
                    note: '',
                    userId: '',
                    createdDate: null,
                    firstName: '',
                    lastName: '',
                    listingId: '',
                },
            ],
            listingDate: '',
            expirationDate: '',
            closedDate: '',
            newListingUntil: '',
            contractDate: '',
            canceledDate: '',
            projectedListDate: '',
            publishedDate: '',
            lastPublishedDate: '',
            tridentDealNumber: '',
            listingFeatures: '',
            totalPastMarketings: 0,
            totalViews: 0,
            totalOpenHouses: 0,
            totalShowings: 0,
            totalMedia: {
                mainPhotos: 0,
                floorPlans: 0,
                showcaseHeroImages: 0,
                photographersUploadImages: 0,
                videos: 0,
                virtualTour: 0,
            },
        },
    },
};

export const mergeListingSlice = createSlice({
    name: 'mergeListing',
    initialState: MergeListingState,
    reducers: {
        updateLoader: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setMergeFromListingRequest: (state, action: PayloadAction<string | null>) => {
            state.mergeListingRequest = {
                ...state.mergeListingRequest,
                fromListingId: action.payload === null ? '' : action.payload,
            };
            state.confirmSuccess = false;
        },
        setMergeToListingRequest: (state, action: PayloadAction<string | null>) => {
            state.mergeListingRequest = {
                ...state.mergeListingRequest,
                toListingId: action.payload === null ? '' : action.payload,
            };
            state.confirmSuccess = false;
        },
        setSearchLoader: (state, action: PayloadAction<boolean>) => {
            state.searchLoader = action.payload;
        },
        setFromListing: (state, action: PayloadAction<SearchedListingListModel[]>) => {
            return {
                ...state,
                searchedFromListings: action.payload,
            };
        },
        setToListing: (state, action: PayloadAction<SearchedListingListModel[]>) => {
            return {
                ...state,
                searchedToListings: action.payload,
            };
        },
        setCheckBox: (state, action: PayloadAction<ChangeEvent<HTMLInputElement>>) => {
            const { name, checked } = action.payload.target;
            state.confirmSuccess = false;
            state.confirmResponseFetch = false;
            if (name === 'all') {
                state.mergeListingRequest = {
                    ...state.mergeListingRequest,
                    includeMlsId: checked,
                    includeTridentId: checked,
                    includeListingDetails: checked,
                    includeListingFeatures: checked,
                    includeAdCopy: checked,
                    includeDomain: checked,
                    includeNotes: checked,
                    includeMarketing: checked,
                    includeAnalytics: checked,
                    includeOpenHouses: checked,
                    includeShowings: checked,
                    includeMedia: checked,
                };
            } else {
                state.mergeListingRequest = {
                    ...state.mergeListingRequest,
                    [name]: checked,
                };
            }
        },
        setMergeListingObject: (
            state,
            action: PayloadAction<MergeListingResponseInterface>,
        ) => {
            return {
                ...state,
                mergeListingResponse: action.payload,
                confirmResponseFetch: true,
                confirmSuccess: false,
            };
        },
        resetMergeConfirm: (state, action: PayloadAction) => {
            return {
                ...state,
                confirmResponseFetch: false,
            };
        },
        setMergeSuccess: (state, action: PayloadAction) => {
            return {
                ...state,
                ...MergeListingState,
                confirmSuccess: true,
            };
        },
        resetMergeRequest: (state, action: PayloadAction) => {
            return {
                ...state,
                ...MergeListingState,
            };
        },
    },
});

/**
 * function will get the from listing list based on the data that is entered in autocomplete
 * @param data
 * @returns
 */
export const fetchFromListing =
    (listingSearchRequest: ListingSearchRequest): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setSearchLoader(true));
            const response = await searchListings(listingSearchRequest);
            if (response.results.length > 0) {
                dispatch(setFromListing(orderBy(response.results, 'status', 'asc')));
            }
        } catch (exception) {
            dispatch(setError(`Failed to fetch the from listing list`));
            Logger.error(
                `Failed to fetch the from listing list ${JSON.stringify(exception)}`,
            );
        } finally {
            dispatch(setSearchLoader(false));
        }
    };

/**
 * function will get the from listing list based on the data that is entered in autocomplete
 * @param data
 * @returns
 */
export const fetchToListing =
    (listingSearchRequest: ListingSearchRequest): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setSearchLoader(true));
            const response = await searchListings(listingSearchRequest);
            if (response.results.length > 0) {
                dispatch(setToListing(orderBy(response.results, 'status', 'asc')));
            }
        } catch (exception) {
            dispatch(setError(`Failed to fetch the to listing list`));
            Logger.error(
                `Failed to fetch the to listing list ${JSON.stringify(exception)}`,
            );
        } finally {
            dispatch(setSearchLoader(false));
        }
    };

export const moveListingPost =
    (mergeListingRequest: MergeListingRequestInterface): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(updateLoader(true));
            const response = await mergeListingApi(mergeListingRequest);
            if (response) {
                dispatch(setMergeListingObject(response));
            }
        } catch (exception) {
            dispatch(setError(`Failed to merge listings`));
            Logger.error(`Failed to merge listings ${JSON.stringify(exception)}`);
        } finally {
            dispatch(updateLoader(false));
        }
    };

export const confirmMergePost =
    (mergeListingRequest: MergeListingRequestInterface): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(updateLoader(true));
            const response = await confirmMergeListingApi(mergeListingRequest);
            if (response.status === 200) {
                dispatch(setMergeSuccess());
            }
        } catch (exception) {
            dispatch(setError(`Failed to merge listings`));
            Logger.error(`Failed to merge listings ${JSON.stringify(exception)}`);
        } finally {
            dispatch(updateLoader(false));
        }
    };

export const {
    setMergeFromListingRequest,
    setMergeToListingRequest,
    setSearchLoader,
    setFromListing,
    setToListing,
    setCheckBox,
    setMergeListingObject,
    resetMergeConfirm,
    setMergeSuccess,
    resetMergeRequest,
    updateLoader,
} = mergeListingSlice.actions;

export const mergeListing = (state: RootState): MergeListingStateInterface =>
    state.listing.mergeListing;

export default mergeListingSlice.reducer;
