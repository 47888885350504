import { Paper, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { FunctionComponent } from 'react';
import { SearchedListingListModel, SearchedMergeListingListModel } from '../../../shared/models/listing/commonModel';
import { currencyFormat, dateFormatMMDDYYY } from '../../../utils/urlUtils';
import { AgentSearchResult } from '../../models/agent/agentModel';
import { UserSearchResponse } from '../../models/users/userModels';
import { OfficesResponse } from '../../models/office/officeModel';
import './index.scss';

interface SearchAutoCompleteModel<T> {
    searchTerm: string;
    setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
    placeholder?: string;
    value?: T;
    results: T[];
    resetKey?: number;
    userSelected(value: T | null): void;
    blurEvent(): void;
    isLoading?: boolean;
    from?: string;
    addIcon?: JSX.Element;
}

const SearchAutoComplete: FunctionComponent<
    SearchAutoCompleteModel<
        | UserSearchResponse
        | AgentSearchResult
        | SearchedListingListModel
        | OfficesResponse
    >
> = ({
    searchTerm,
    setSearchTerm,
    placeholder,
    value,
    results,
    resetKey,
    userSelected,
    blurEvent,
    isLoading,
    from = 'user',
    addIcon,
}) => {
    const displayData = (
        data:
            | UserSearchResponse
            | AgentSearchResult
            | SearchedListingListModel
            | OfficesResponse,
    ) => {
        if (from === 'user') {
            const option = data as UserSearchResponse;
            return option.firstName + ' ' + option.lastName + ' - ' + option.brandEmail;
        } else if (from === 'agent') {
            const option = data as AgentSearchResult;
            const preferredFirstName =
                option.preferredFirstName &&
                option.preferredFirstName !== option.firstName
                    ? `"${option.preferredFirstName}"`
                    : '';

            return `${option.firstName} ${preferredFirstName} ${option.lastName}`;
        } else if (from === 'listing') {
            const option = data as SearchedListingListModel;
            const add1 = option.address1 ? option.address1 : '';
            const add2 = option.address2 ? ', ' + option.address2 : '';
            const stat = option.status ? ' - ' + option.status : '';
            const curr = option.currentPrice
                ? ' - $' + currencyFormat(option.currentPrice)
                : '';
            const rfgId = option.rfgid ? '- ' + option.rfgid : '';
            const mlsNumber = option.mlsNumber ? '- ' + option.mlsNumber : '';
            return add1 + add2 + stat + curr + rfgId + mlsNumber;
        } else if(from === 'mergeListing') {
            const option = data as SearchedMergeListingListModel;
            const add1 = option.address1 ? option.address1 : '';
            const add2 = option.address2 ? ', ' + option.address2 : '';
            const stat = option.status ? ' - ' + option.status : '';
            const mlsNumber = option.mlsNumber ? (' - (' + option.mlsNumber + ')') : '';
            const published = option.publishSite ? ' - Published' : ' ';
            const businessType = option.businessPurpose ? (' - ' + option.businessPurpose) : '';
            const agentName = option.agentName ? (' - ' +option.agentName) : '';
            let price = option.currentPrice ? ' - $' + currencyFormat(option.currentPrice): '';
            let date = '';
            if(option.status === 'Active') {
                date = option.listingDate ? ' - Listing Date: ' + dateFormatMMDDYYY(option.listingDate as string, '', true) : '';
            }
            if(option.status === 'Sold') {
                date = option.closedDate ? ' - Sold Date: ' + dateFormatMMDDYYY(option.closedDate as string, '', true) : '';
            }
            if(option.status === 'Pending') {
                price = option.offerPrice ? ' - $' + currencyFormat(option.offerPrice): '';
                date = option.contractDate ? ' - Contract Date: ' + dateFormatMMDDYYY(option.contractDate as string, '', true) : '';
            }
            if(option.status === 'Expired') {
                date = option.expirationDate ? ' - Expiration Date: ' + dateFormatMMDDYYY(option.expirationDate as string, '', true) : '';
            }
            if(option.status === 'Temp Off-Market') {
                date = option.canceledDate ? ' - Canceled Date: ' + dateFormatMMDDYYY(option.canceledDate as string, '', true) : '';
            }
            if(option.status === 'Coming Soon') {
                date = option.projectedListDate ? ' - Projected List Date: ' + dateFormatMMDDYYY(option.projectedListDate as string, '', true) : '';
            }
            if(option.status === 'Draft') {
                price = option.offerPrice ? ' - $' + currencyFormat(option.offerPrice): '';
            }
            return add1 + add2 + mlsNumber + stat + published + businessType + agentName + price + date;
        } else if (from === 'salesTeamMember') {
            const option = data as AgentSearchResult;
            return option.name + ' - ' + option.office;
        } else if (from === 'office') {
            const option = data as OfficesResponse;
            return option.officeName;
        } else {
            return '';
        }
    };
    return (
        <Autocomplete
            value={value}
            key={resetKey}
            forcePopupIcon={false}
            options={results}
            renderInput={(params) => (
                <>
                    <TextField
                        {...params}
                        InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                        }}
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="autoComplete formControlRoot"
                        placeholder={placeholder || ''}
                    />
                    {(addIcon ?
                        addIcon
                        : '')}
                    </>
            )}
            getOptionLabel={(option) => displayData(option)}
            onChange={(event, value) => userSelected(value)}
            className="searchAutoComplete"
            onBlur={() => blurEvent()}
            PaperComponent={({ children }) => (
                <Paper
                    style={{
                        fontSize: 12,
                        fontFamily: 'BentonSansRegular',
                        marginTop: '5px',
                    }}
                >
                    {children}
                </Paper>
            )}
            loading={isLoading}
            loadingText="Loading..."
            clearOnBlur={true}
            noOptionsText="No Results Found"
        />
    );
};

export default SearchAutoComplete;
