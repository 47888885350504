import { ListingSearchRequest, SearchedListingListModel } from "../../../shared/models/listing/commonModel";
import { PaginationResponse } from "../../../shared/models/pagination/paginationModels";
import { postCall, postCallRaw } from "../../../utils/api/apiWrapper";
import { MergeListingRequestInterface, MergeListingResponseInterface } from "./mergeListingModels";

export function searchListings(
    request: ListingSearchRequest,
): Promise<PaginationResponse<SearchedListingListModel>> {
    return postCall<ListingSearchRequest, PaginationResponse<SearchedListingListModel>>(
        `listing/search`,
        request,
    );
}

export function mergeListingApi(
    request: MergeListingRequestInterface
): Promise<MergeListingResponseInterface> {
    return postCall<MergeListingRequestInterface, MergeListingResponseInterface>(
        'listing/overview',
        request
    );
}

export function confirmMergeListingApi(
    request: MergeListingRequestInterface
): Promise<Response> {
    return postCallRaw<MergeListingRequestInterface>(
        'listing/merge',
        request
    );
}